/* Hero Section */

.hero {
    background-image: url('../images/background_patterns/hero_pattern.svg');
    background-position: left -10% top -40%;
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 12rem 18rem 10rem;
}

.beta-alert {
    margin-bottom: 4rem;
    /* height: max-content; */
}

.beta-alert-text-pre{
    padding: .5rem 3rem;
    font-size: 1.4rem;
    /* letter-spacing: 0px; */
    background-color: var(--primary-yellow);
    color: white;
    border: 2px solid #71530C;
    border-radius: 0px;
    /* border: 0px; */
}

.beta-alert-text-post{
    padding: .5rem 1.2rem .4rem 1.5rem;
    font-size: 1.4rem;
    letter-spacing: .3rem;
    background-color: white;
    border: 2px solid #71530C;
    border-radius: 7px;
    color: #71530C;
}

.beta-alert-text-post::after {
    margin-right: -.2rem;
}

.hero__box {
    display: flex;
    position: relative;
    min-height: 36rem;
}

.hero__box-left {
    width: 65%;
    padding-top: 3rem;
}

.hero__box-right {
    width: 35%;
    padding-left: 8rem;
    position: relative;
}

.hero__box-right .nav {
    display: none;
}

.hero__nft-wrapper {
    position: absolute;
    top: 0;
    left: 8rem;
}

.hero__title {
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-yellow);
    margin-bottom: 2rem;
}

.hero__desc {
    font-size: 2.7rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--primary-yellow);
    margin-bottom: 4rem;
    margin-right: 7rem;
}

.hero__btn {
    display: inline-block;
    width: 32rem;
    background-color: var(--bg-yellow-light);
    padding: 1rem 3rem;
    border-radius: 3rem;
    font-size: 2.7rem;
    font-weight: 500;
    color: var(--primary-yellow);
    cursor: pointer;
}

.hero__nft-img-box {
    padding-bottom: 2.5rem;
}

.hero__nft-img {
    height: 27rem;
    border-radius: 3rem;
    box-shadow: 3px 0px 5px #00000029;
    transform: rotate(10deg);
}

.hero__nft-name {
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--primary-grey);
    margin-bottom: .5rem;
}

.hero__nft-owner {
    font-size: 1.7rem;
    font-weight: 400;
    color: var(--primary-grey);
}


/* Top Fundraiser */

.topfund__title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.topfund__title-text {
    font-size: 5rem;
    font-weight: 600;
    color: white;
}

.topfund__title-icon {
    height: 6.5rem;
    margin: 0 1rem;
}

.topfund__desc {
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-yellow);
    line-height: 1.3;
    text-align: center;
    padding: 0 20rem;
    margin-bottom: 6rem;
}

.topfund-cards {
    display: flex;
    gap: 10rem;
    width: fit-content;
    margin: auto;
    min-width: 100%;
}

.topfund-card-wrapper {
   width: calc((100% - 20rem)/3);
}


/* My Octopi Section */

.myoctopi__title-box {
    margin-bottom: 3rem;
}

.column1,
.column3 {
    width: 25%;
}

.column2 {
    width: 50%;
}

.myoctopi__title {
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-yellow);
    text-align: center;
}

.myoctopi__viewall {
    font-size: 2.2rem;
    font-weight: 600;
    color: var(--primary-grey);
    text-align: right;
}

.no-octopi-text {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--primary-grey);
    text-align: center;
}

.link-design {
    color: inherit;
    text-decoration: underline;
}

.my-nfts {
    width: 87%;
    margin: auto;
    display: flex;
    gap: 5rem;
}

.nft-grid-simplecard__wrapper {
    width: calc((100% - 10rem) / 4);
    
}


/* How Unique */

.how-unique {
    text-align: center;
}

.how-unique__title {
    font-size: 5rem;
    font-weight: 600;
    color: var(--secondary-yellow);
    margin-bottom: 3rem;
    text-align: center;
}

.hr-line {
    width: 58%;
    height: .3rem;
    background-color: white;
    margin: 0 auto 3rem;
}

.how-unique__desc {
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-yellow);
    line-height: 1.3;
    text-align: center;
    padding: 0 20rem;
    margin-bottom: 6rem;
}

.how-unique__cards {
    margin-bottom: 7rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem;
}

.how-unique__card {
    background-color: white;
    padding: 3.5rem 5rem;
    border-radius: 1.5rem;
    position: relative;
    cursor: pointer;
}

.how-unique__card-icon-box {
    text-align: center;
}

.how-unique__card-icon {
    height: 7rem;
}

.how-unique__card-title {
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-blue);
    text-align: center;
    margin: 2rem 0;
}

.how-unique__card-desc {
    font-size: 1.3rem;
    font-weight: 400;
    color: var(--primary-grey);
    text-align: justify;
    line-height: 1.3;
    margin: 0 2rem;
    max-width: 50rem;
}

.how-unique__card-btn {
    text-align: center;
    cursor: pointer;
}

.how-unique__card-btn-text {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--secondary-blue);
}

.faqs-link-wrapper {
    display: inline-block;
    text-align: center;
    margin: auto;
}

.faqs-link {
    font-size: 2rem;
    font-weight: 500;
    color: var(--secondary-yellow);
    text-align: center;
    display: inline-block;
    
}


/* Wagmi */
#wagmi {
    padding: 10rem 12rem 2rem;
}
.wagmi__title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.wagmi__title-heart-icon {
    height: 4rem;
}

.wagmi__title-nft-icon {
    height: 7rem;
}

.wagmi__title-text {
    font-size: 5rem;
    font-weight: 600;
    color: var(--primary-yellow);
}

.plus-sign {
    margin: 0 2rem;
}

.wagmi__desc {
    font-size: 2rem;
    font-weight: 400;
    color: var(--primary-grey);
    text-align: center;
    line-height: 1.3;
}