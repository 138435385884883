/* Faqs */

.faqs__qas-box {
  margin-bottom: 3rem;
}
.faqs__md {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primary-grey);
  margin-bottom: 1.5rem;
  line-height: 3rem;
}

.faqs__md h2 {
  color: var(--primary-yellow);
}

.faqs__qas:not(:last-child) {
  margin-bottom: 3rem;
}

.faqs__qstn {
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-yellow);
  margin-bottom: 1.5rem;
}

.faqs__ans {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primary-grey);
  line-height: 1.3;
}

/* My Octopi Page */

.myoctopi-page__title-box {
  margin-bottom: 3rem;
}

.sale-history-link {
  font-size: 2.2rem;
  font-weight: 600;
  color: var(--primary-grey);
  text-align: right;
}

/* History */

.history__title {
  margin-bottom: 3rem;
}

.history__table {
  margin-bottom: 5rem;
}

.history__table-row:first-child {
  border-bottom: 1px solid var(--secondary-grey);
}

.history__table-column {
  padding: 1.2rem 0;
  text-align: center;
}

.history__table-header {
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--primary-grey);
}

.history__table-data {
  font-size: 1.7rem;
  font-weight: 400;
  color: var(--primary-grey);
}

.faqs__title{
  margin-bottom: 2rem;
}

.audits__title {
  margin-bottom: 2rem;
}

.faqs__md ul + p {
  padding-left: 30px;
}

.faqs__md p {
  line-height: 1.3;
  padding-bottom: 10px;
}

.faqs__md ul {
  padding-left: 30px;
  padding-top: 10px;
}

.faqs__md h2 {
  line-height: 6rem;
}

.faqs__md ol {
  padding-top: 10px;
  padding-left: 30px;
}
