/* Header & Navbar */

header {
    padding: 2rem 5rem;
    background-color: var(--bg-yellow);
    box-shadow: 0px 6px 6px #00000029;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    scroll-margin-top: -50px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.nav__logo {
    height: 5rem;
}

.nav__menu-items {
    display: flex;
    align-items: center;
}

.nav__menu-items a {
    text-decoration: none;
}

.nav__menu-item-box {
    display: flex;
    align-items: center;
    margin-left: 4.5rem;
    cursor: pointer;
}

.nav__menu-item {
    list-style: none;
    font-size: 2rem;
    font-weight: 500;
    color: var(--primary-grey);
}

.nav__menu-item-icon {
    height: 3.5rem;
    margin-left: 1rem;
}

.nav__menu-item-btn {
    padding: .7rem 1rem;
    border: 2px solid var(--primary-grey);
    border-radius: 3rem;
    position: relative;
}

.wallet-icon {
    height: 2.5rem;
}
.wallet-addr {
    padding: 0 2rem;
}
.nav__menu-item-btn-info {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Body */

.template-body {
    width: 100%;
    min-height: 50rem;
    /* z-index: 10; */
}

/* Footer */

footer {
    min-height: 50rem;
    padding: 2rem 5rem 2rem;
    background-image: url("../images/background_patterns/bottom_2.svg"),
        url("../images/background_patterns/bottom_1.svg");
    background-position: left bottom 10%, left bottom -25%;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, 100%;
    position: relative;
    display: flex;
    margin-top: -10rem;
}

.footer__social-icons {
    display: flex;
    gap: 2.5rem;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, 0);
}

.footer__social-icon {
    height: 2.7rem;
    cursor: pointer;
}

.footer__copyright {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.footer__copyright-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--primary-grey);
}

.footer__poweredby {
    height: 5rem;
}

.footer__pwb {
    background-color: transparent;
    width: 150px;
    height: 50px;
    perspective: 1000px;
}

.footer__pwb-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 4s;
    transform-style: preserve-3d;
    animation-name: flip;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    /* animation-delay: 3s; */
    animation-direction: alternate;
}

.footer__pwb-avalanche,
.footer__pwb-reddev {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    /* Safari */
    backface-visibility: hidden;
    transform: rotateX(0deg)
}

.footer__pwb-reddev {
    transform: rotateY(180deg);
}

.front {
    height: 50px;
}

.back {
    height: 50px;
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }
    40% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    60% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}