/* Mint */

.mint__title {
    margin-bottom: 3rem;
}

.mint__box {
    margin: 0 auto 0rem;
    padding: 3rem 7rem;
    border-radius: 1.5rem;
    background-color: white;
    box-shadow: 0px 0px 15px #8d8d8d56;
    width: fit-content;
    min-width: 100rem;
    max-width: 100rem;
    height: 50rem;
}

.mint__box-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.mint__steps {
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    /* margin-bottom: 2.5rem; */
    padding: 0 10px;
    height: 85%;
}

.mint__steps-left {
    width: 70%;
}
/* .mint__steps-right {
    width: 30%;
} */

.mint__bg-box {
    position: relative;
    width: 50rem;
    justify-content: space-between;
    padding: 0rem 2rem;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0px 0px 10px #00000029;
}

.mint__step-title {
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--primary-grey);
    margin-bottom: 2rem;
}

.bg-palette {
    padding-bottom: 4rem;
}

.bg-palette__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bg-box__wrapper--selected {
    border: 2px solid var(--secondary-grey);
    border-radius: 3px;
}

.bg-box {
    width: 4rem;
    height: 4rem;
    border-radius: 3px;
    margin: .3rem;
    cursor: pointer;
    position: relative;
}

.rare-tooltip {
    position: relative;
}

.ttwrapper {
    position: absolute;
    visibility: hidden;
}
  
.tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 10;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* margin-left: -60px; */
}
  
/* .rare-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-radius: 5px;
} */
  
.rare-tooltip:hover .tooltiptext {
    visibility: visible;
}

.rare-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 1.3rem;
    height: 1.2rem;
    line-height: 1.2rem;
    /* letter-spacing: .1rem; */
    font-weight: 600;
    text-transform: uppercase;
    color: var(--primary-grey);
    background-color: rgba(255, 255, 255, 0.5);
    animation: blink 12s ease-in 2s infinite normal none;
}

@keyframes blink {
    0% {
        color: var(--primary-grey);
        background-color: rgba(255, 255, 255, 0.5);
    }
    20% {
        color: var(--primary-grey);
        background-color: rgba(255, 255, 255, 0.5);
    }
    25% {
        color: transparent;
        background-color: transparent;
    }
    30% {
        color: transparent;
        background-color: transparent;
    }
    50% {
        color: white;
        background-color: #54505080;
    }
    70% {
        color: white;
        background-color: #54505080;
    }
    75% {
        color: transparent;
        background-color: transparent;
    }
    80% {
        color: transparent;
        background-color: transparent;
    }
    100% {
        color: var(--primary-grey);
        background-color: rgba(255, 255, 255, 0.5);
    }
}

.bg-price {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primary-grey);
    margin-top: 1rem;
    display: inline-block;
    width: max-content;
    /* position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0); */
}

.pattern {
    justify-content: space-between;
}

.pattern-box {
    background-size: 180px;
    background-position: center;
}

.nft-name__dropdown-wrapper {
    position: relative;
    width: 50rem;
}

.nft-name__dropdown-top {
    padding: 1rem 2rem;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.nft-name {
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--primary-grey);
}

.chevron-down {
    height: 1.7rem;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
}

.arrow-left, .arrow-right {
    height: 1.4rem;
}

.arrow-left {
    margin-right: .8rem;
}

.arrow-right {
    margin-left: .8rem;
}

.nft-name__dropdown-bottom {
    width: 100%;
    max-height: 24rem;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 10px;
    text-align: center;
    position: absolute;
    top: calc(100% + 1.5rem);
    left: 0;
    background-color: white;
    overflow-y: scroll;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
}

/* .nft-name__dropdown-bottom::-webkit-scrollbar {
    width: 0;
    height: 0;
} */

.nft-name__list {
    list-style: none;
    padding: 1rem 2rem;
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--primary-grey);
    cursor: pointer;
}

.nft-name__lists {
    border-radius: inherit;
}

.nft-name__list:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.nft-name__list:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.nft-name__list:hover {
    background-color: var(--bg-yellow-light);
}

.checkbox-wrapper {
    margin-top: 6rem;
    cursor: pointer;
}

.checkbox {
    width: 1.6rem;
    height: 1.6rem;
    accent-color: var(--primary-grey);
    margin-right: 7px;
    vertical-align: middle;
    cursor: pointer;
}

.checkbox-text {
    font-size: 1.6rem;
    color: var(--primary-grey);
    font-weight: 500;
    vertical-align: middle;
    cursor: pointer;
}

.mint__dist-title {
    font-size: 1.7rem;
    font-weight: 500;
    color: var(--primary-grey);
    /* margin-bottom: .5rem; */
}

.mint__dist-desc {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--primary-grey);
    margin-top: -1rem;
}

.mint__dist-presets {
    width: 85%;
    margin: 2rem 0 2rem;
}

 .preset-wrapper {
    min-height: 25rem;
} 

.mint__dist-presets--predefined {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3.5rem;
    column-gap: 5rem;
    margin-bottom: 3.5rem;
}

.dist-preset__box {
    padding: 1.3rem 1rem;
    border: 1px solid var(--secondary-grey);
    border-radius: 1rem;
    text-align: center;
    cursor: pointer;
}

.dist-preset__box--selected {
    background-color: var(--bg-yellow-light);
    border: 1px solid var(--bg-yellow-light);
}

.large-box {
    width: 100%;
}

.dist-preset__text {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--primary-grey);
}

.pie-wrapper {
    position: relative;
    margin-top: -2rem;
}

.pie {
    position: relative;
    width: 25rem;
}

.pie-nft {
    height: 14rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
}

.mint-right__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mint-preview__title {
    text-align: center;
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 2rem;
}

.pie-labels__wrapper {
    margin-left: 3rem;
    /* margin-top: -1rem; */
}

.pie-label__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
}

.pie-label__color-box {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .3rem;
    margin-right: .8rem;
}

.pie-label {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primary-grey);
}

.mint__btn {
    background-color: white;
    color: var(--secondary-blue);
    /* border: 1px solid #CBCBCB;
    border-radius: 25px;
    padding: 7px 7px; */
    font-size: 1.7rem;
    cursor: pointer;
}

.arrow-group {
    display: flex;
    align-items: center;
}

.no-border {
    border: 0px
}

.mint__btn:disabled {
    color: var(--secondary-grey);
    cursor: not-allowed;
}

.mint__btn-next {
    float: right;
}

/* .mint__btn-prev span {
    font-size: 3.5rem;
    padding-right: 5px;
    vertical-align: -0.25rem;
}

.mint__btn-next span {
    font-size: 3.5rem;
    padding-left: 5px;
    vertical-align: -0.45rem;
} */


.mint__final-list {
    width: fit-content;
    position: relative;
    justify-content: space-between;
    padding: 3.8rem 4rem;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0px 0px 10px #00000029;
    min-height: 33rem;
    max-height: 33rem;
    overflow-y: auto;
}

.final-data {
    display: flex;
    column-gap: 1rem;
}

.final-data__dist-division th,
.final-data__dist-division td {
    padding: 1.2rem 2rem;
}

.final-data__dist-division tr:not(:last-child) {
    border-bottom: 1px solid var(--secondary-grey);
}

.final-data__dist-division {
    border-collapse: collapse;
}

.final-data__dist-division th:not(:first-child),
.final-data__dist-division td:not(:first-child) {
    text-align: center;
}

.final-data__nft-styles td {
    padding: .7rem 0;
}

.final-data__nft-styles td:first-child {
    padding-right: 1rem;
}

.final-data__nft-styles td:not(:first-child) {
    padding-left: 1rem;
}


.final-data__nft-box {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 3px;
    display: inline-block;
}

.final-rarity {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--primary-grey);
    margin-top: 4rem;
}

.slider-box {
    padding: 1rem 2rem;
    border-radius: 1rem;
    background-color: white;
    border: 1px solid var(--secondary-grey);
    margin-bottom: 1.5rem;
    /* height: 22rem; */
}

.slider-group:not(:last-child) {
    margin-bottom: .5rem;
}

.slider-info__text {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--primary-grey);
}

.mint-warning {
    text-align: center;
    padding: 5rem;
}

.mint-warning-msg {
    font-size: 2.5rem;
    font-weight: 400;
    color: var(--primary-grey);
    margin-bottom: 5rem;
    text-align: left;
    line-height: 1.3;
}

.mint-warning-btn {
    width: 15rem;
    font-size: 2.2rem;
    font-weight: 400;
    background-color: var(--primary-yellow);
    color: white;
    border: none;
    border-radius: 3rem;
    padding: .7rem 4rem;
    margin: 0 1.7rem;
    cursor: pointer;
}

.rarity-box {
    width: 50rem;
    margin-top: 4rem;
    box-shadow: 0px 0px 10px #00000029;
    border-radius: 1rem;
    padding: 2rem;
}

.rarity-text {
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--primary-yellow);
    line-height: 1.5;
}

.table-rare-text {
    font-size: 1rem;
    color: var(--primary-yellow);
    font-weight: 500;
    padding-top: 0px !important;
}

.rare-name {
    color: var(--primary-red);
    font-size: 1.75rem;
    font-weight: 600;
}